import styles from "./buildingOneTimesheet.module.scss";
import { observer } from "mobx-react-lite";
import { useStores } from "stores/index";
import { useEffect, useState } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import SwitchedTabs from "shared/ui/Tabs/SwitchedTabs";
import BuildingOneTimesheetInfoLegend from "./BuildingOneTimesheetInfo/BuildingOneTimesheetInfoLegend";
import BuildingOneTimesheetInfo from "./BuildingOneTimesheetInfo";
import BuildingOneTimesheetChart from "./BuildingOneTimesheetChart";
import BuildingOneTimesheetHeader from "./BuildingOneTimesheetHeader";
import BuildingOneWorkshiftForm from "../BuildingOneWorkshiftForm";
import LoadedComponent from "widgets/LoadedComponent";

import SearchInput from "shared/ui/Inputs/SearchInput/index";
import Tooltip from "shared/ui/Tooltip";
import { Formik } from "formik";

import iconQuestionWhite from "shared/assets/images/mainIcons/iconQuestion/iconQuestionWhite.svg";
import { ErrorBoundary } from "react-error-boundary";
import ErrorFallback from "widgets/LoadedComponent/Error/ErrorFallback";
import { Button, ButtonTheme } from "shared/ui/Button";

const tabs: {
  id: string;
  title: string;
}[] = [
  { id: "timesheet", title: "Табель" },
  {
    id: "schedule",
    title: "График"
  }
];

const BuildingOneTimesheet = () => {
  const { id } = useParams();
  const {
    menuStore,
    buildingOneStore,
    buildingOneAddWorkshiftFormStore,
    buildingOneTimesheetStore
  } = useStores();
  const [searchParams, setSearchParams] = useSearchParams();
  const [isOpenedLegend, setIsOpenedLegend] = useState(false);

  useEffect(() => {
    if (searchParams.toString()?.length) {
      const filterParams: { [key: string]: string } = {};

      for (const [key, value] of searchParams.entries()) {
        filterParams[key] = value;
      }

      setSearchParams(filterParams);

      buildingOneTimesheetStore.setSelectedYear(+filterParams.year, id);
      buildingOneTimesheetStore.setSelectedMonth(+filterParams.month, id);
    } else if (
      buildingOneTimesheetStore.allOpenedTabels[id]?.tabel &&
      buildingOneTimesheetStore.allYearsAndMonth[id]?.months &&
      Object.values(buildingOneTimesheetStore.allYearsAndMonth[id].months)
        .length
    ) {
      setSearchParams({
        year: `${buildingOneTimesheetStore.year[id]}`,
        month: `${buildingOneTimesheetStore.month[id]}`
      });
    }
    buildingOneTimesheetStore.setSelectedBuildingForTimesheet(id);

    menuStore.setOpenedModule("buildingOne");
    menuStore.setOpenedSubmodule("buildingOneTimesheet");
    menuStore.sendTabId(id);
    menuStore.updateWindow({
      mainPath: `/building/id=${id}`,
      path: `/building/id=${id}/timesheet`
    });
  }, [id]);

  useEffect(() => {
    if (
      !buildingOneTimesheetStore.isLoading &&
      !searchParams.toString()?.length &&
      buildingOneTimesheetStore.allYearsAndMonth[id]?.months &&
      Object.values(buildingOneTimesheetStore.allYearsAndMonth[id].months)
        .length
    ) {
      setSearchParams({
        year: `${buildingOneTimesheetStore.year[id]}`,
        month: `${buildingOneTimesheetStore.month[id]}`
      });
    }
  }, [buildingOneTimesheetStore.isLoading]);

  return (
    <LoadedComponent
      isLoading={buildingOneTimesheetStore.isLoading}
      error={buildingOneTimesheetStore.error[id]}
    >
      <>
        {(buildingOneTimesheetStore.allOpenedTabels[id]?.tabel &&
          Object.values(buildingOneTimesheetStore.allOpenedTabels[id].tabel)
            .length) ||
        (buildingOneTimesheetStore.allOpenedTabels[id]?.ws_list &&
          Object.values(buildingOneTimesheetStore.allOpenedTabels[id].ws_list)
            .length) ||
        (buildingOneTimesheetStore.allYearsAndMonth[id]?.months &&
          Object.values(buildingOneTimesheetStore.allYearsAndMonth[id].months)
            .length) ? (
          <div className={styles.page}>
            <div className={styles.header}>
              <p>{buildingOneStore.selectedOne.title}</p>
              {buildingOneTimesheetStore.selectedMode[id] === "schedule" ? (
                <Tooltip
                  color="blue-lazure"
                  text="Легенда для графика находится в разработке"
                >
                  <Button theme={ButtonTheme.FLOATING}>
                    <img src={iconQuestionWhite} className={styles.imgLegend} />
                    Легенда
                  </Button>
                </Tooltip>
              ) : (
                <Button
                  theme={ButtonTheme.FLOATING}
                  onClick={() => setIsOpenedLegend(!isOpenedLegend)}
                  id="BuildingOneTimesheet_changeTab"
                >
                  <img src={iconQuestionWhite} className={styles.imgLegend} />
                  Легенда
                </Button>
              )}
              {buildingOneTimesheetStore.selectedMode[id] === "timesheet" &&
                isOpenedLegend && (
                  <ErrorBoundary FallbackComponent={ErrorFallback}>
                    <BuildingOneTimesheetInfoLegend
                      setIsOpenedLegend={setIsOpenedLegend}
                    />
                  </ErrorBoundary>
                )}
            </div>
            <ErrorBoundary FallbackComponent={ErrorFallback}>
              <SwitchedTabs
                tabs={tabs}
                selectedTab={buildingOneTimesheetStore.selectedMode[id]}
                changeActiveTab={(mode: string) =>
                  buildingOneTimesheetStore.setSelectedMode(mode, id)
                }
              />
            </ErrorBoundary>
            {buildingOneTimesheetStore.selectedMode[id] === "timesheet" ? (
              <Formik
                initialValues={{
                  searchInput: buildingOneTimesheetStore.searchValue[id] || ""
                }}
                onSubmit={() => {
                  return;
                }}
              >
                {({ setFieldValue }) => (
                  <ErrorBoundary FallbackComponent={ErrorFallback}>
                    <SearchInput
                      name={`BuildingOneTimesheet_search_${id}`}
                      placeholder="Поиск по сотрудникам"
                      onChange={(e) => {
                        setFieldValue(
                          "searchInput",
                          e.target.value.toLowerCase()
                        );
                        buildingOneTimesheetStore.setSearchValue(
                          id,
                          e.target.value.toLowerCase()
                        );
                      }}
                      clearSearch={() => {
                        setFieldValue("searchInput", "");
                        buildingOneTimesheetStore.setSearchValue(id, "");
                      }}
                      withoutBtn
                      fixWidth
                    />
                  </ErrorBoundary>
                )}
              </Formik>
            ) : (
              ""
            )}
            <ErrorBoundary FallbackComponent={ErrorFallback}>
              <BuildingOneTimesheetHeader />
            </ErrorBoundary>
            <ErrorBoundary
              FallbackComponent={ErrorFallback}
              resetKeys={[buildingOneTimesheetStore.selectedMode[id]]}
            >
              {buildingOneTimesheetStore.selectedMode[id] === "timesheet" ? (
                <BuildingOneTimesheetInfo />
              ) : (
                <BuildingOneTimesheetChart />
              )}
            </ErrorBoundary>
          </div>
        ) : (
          <div className={styles.withoutWorkshifts}>
            Не найдены смены у объекта
          </div>
        )}
        {buildingOneAddWorkshiftFormStore.isOpenedFromSubmodule ===
        "timesheet" ? (
          <ErrorBoundary FallbackComponent={ErrorFallback}>
            <BuildingOneWorkshiftForm
              date={buildingOneTimesheetStore.selectedDate}
            />
          </ErrorBoundary>
        ) : (
          ""
        )}
      </>
    </LoadedComponent>
  );
};

export default observer(BuildingOneTimesheet);
