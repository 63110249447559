import { observer } from "mobx-react-lite";
import { useStores } from "stores/index";
import styles from "./staffOneInfo.module.scss";
import { useParams } from "react-router-dom";
import { RefObject, useEffect, useState } from "react";

import StaffOneInfoOne from "./StaffOneInfoOne";
import { ErrorBoundary } from "react-error-boundary";
import ErrorFallback from "widgets/LoadedComponent/Error/ErrorFallback";
import { calculateDays } from "shared/utils/helpers/calculateDays";
import { calculateAge } from "shared/utils/helpers/calculateAge";
import { formatPhoneNumber } from "shared/utils/helpers/formatPhoneNumber";

export type DictType =
  | {
      [key: string]:
        | string
        | number
        | {
            [key: string]: string | number | { [key: string]: string | number };
          };
    }
  | string
  | number;

type StaffOneInfoProps = {
  componentRef: RefObject<null>;
  openedWidgets: string[];
  changeOpenedWidgets: (
    col: "rightCol" | "leftCol",
    type: "add" | "delete",
    widgets: string[]
  ) => void;
  isScroll: boolean;
  top: string;
};

const StaffOneInfo = ({
  componentRef,
  openedWidgets,
  changeOpenedWidgets,
  isScroll,
  top
}: StaffOneInfoProps) => {
  const { staffOneStore } = useStores();
  const { id } = useParams();

  const [allWidgets, setAllWidgets] = useState<string[]>([]);
  const blockedFields = staffOneStore.blockedCols[id] || [];

  useEffect(() => {
    const widgets: string[] = [];
    Object.values(staffOneStore.staffGroups).forEach((group) => {
      widgets.push(group.id);
    });
    widgets.push("Настройки для приложения");
    setAllWidgets(widgets);
  }, []);

  return (
    <>
      <div
        className={
          !isScroll ? styles.openedAllWidgets : styles.openedAllWidgets_miniTop
        }
        style={{
          top: !isScroll ? top : ""
        }}
      >
        <div
          id={`StaffOneInformation_openedAllWidgetsLeft_${id}`}
          className={`${styles.openedAllWidgets__text} ${
            openedWidgets?.length ? styles.openedAllWidgets__text_active : ""
          }`}
          onClick={() => {
            changeOpenedWidgets(
              "leftCol",
              openedWidgets?.length ? "delete" : "add",
              allWidgets
            );
          }}
        >
          {openedWidgets?.length ? "Свернуть всё" : "Развернуть всё"}
        </div>
      </div>
      {Object.values(staffOneStore.staffGroups).map((group, index) => {
        const allCols = Object.values(group.columns);
        const dict: DictType = {};

        allCols.forEach((key) => {
          if (blockedFields.includes(key)) return;

          const value = staffOneStore.info[key];
          const checkValue = (val: number | string) => {
            if (val) {
              return val;
            } else return "";
          };

          if (index === 2 && staffOneStore.companiesTabs.length) {
            dict.company = staffOneStore.info.company;
          }

          if (
            value ||
            key === "photo" ||
            key === "photo_alt" ||
            key === "snils"
          ) {
            const infoObj: {
              [key: string]:
                | string
                | number
                | { [key: string]: string | number };
            } = {};
            const rowObj: { [key: string]: string | number } = {};

            switch (key) {
              case "birthday":
                infoObj["birthday"] = value;
                infoObj["age"] = calculateAge(value);
                break;
              case "snils":
                dict["snils"] = value;
                break;
              case "training_date_start":
                rowObj["training_date_start"] = value;
                if (
                  checkValue(value) &&
                  allCols.includes("training_date_finish") &&
                  checkValue(staffOneStore.info["training_date_finish"])
                ) {
                  rowObj["training_date_finish"] =
                    staffOneStore.info["training_date_finish"];
                  rowObj["days"] = calculateDays(
                    staffOneStore.info["training_date_start"],
                    staffOneStore.info["training_date_finish"]
                  );
                }

                infoObj["education"] = rowObj;
                break;
              case "training_date_finish":
                if (!allCols.includes("training_date_start")) {
                  dict[key] = value;
                }
                break;

              case "premium_price":
                if (
                  checkValue(value) &&
                  (!allCols.includes("salary") || !staffOneStore.info["salary"])
                ) {
                  infoObj[key] = value;
                }
                break;

              case "salary":
                if (
                  allCols.includes("premium_price") &&
                  checkValue(staffOneStore.info["premium_price"])
                ) {
                  infoObj["premium_price"] =
                    staffOneStore.info["premium_price"];
                }
                if (checkValue(value)) {
                  infoObj[key] = value;
                }
                break;

              case "trud_dog":
                infoObj["trud_dog"] = value;
                if (
                  checkValue(value) &&
                  allCols.includes("trud_dog_date") &&
                  checkValue(staffOneStore.info["trud_dog_date"])
                ) {
                  infoObj["trud_dog_date"] =
                    staffOneStore.info["trud_dog_date"];
                }
                break;
              case "trud_dog_date":
                if (!allCols.includes("trud_dog")) {
                  dict[key] = value;
                }
                break;
              case "photo_alt":
                if (Object.values(value).length) {
                  dict[key] = value;
                }

                break;
              case "phone":
                Object.entries(value).forEach((item) => {
                  const phoneNumber = formatPhoneNumber(item[1]["number"]);

                  const phoneObj: Partial<{
                    confirm: number;
                    phone: string;
                    phoneComment: string;
                  }> = {};
                  if (item[1]["confirm"] === 1) {
                    phoneObj["confirm"] = item[1]["confirm"];
                    phoneObj["phone"] = phoneNumber;
                    phoneObj["phoneComment"] = item[1]["comment"];
                    infoObj[item[0]] = phoneObj;
                  } else {
                    phoneObj["phone"] = phoneNumber;
                    phoneObj["phoneComment"] = item[1]["comment"];
                    infoObj[item[0]] = phoneObj;
                  }
                });
                break;
              case "email":
                if (
                  staffOneStore.info["email_confirm"] === 0 ||
                  staffOneStore.info["email_confirm"] === 1
                ) {
                  infoObj["email"] = value;
                  infoObj["confirm"] = staffOneStore.info["email_confirm"];
                } else {
                  infoObj["email"] = value;
                }
                break;
              case "company":
                if (index !== 0 && Object.keys(value).length) {
                  dict[key] = value;
                }
                break;
              case "uid":
              case "name":
              case "surname":
              case "patronymic":
              case "email_confirm":
              case "employment_title":
              case "photo":
                break;

              default:
                if (checkValue(value)) {
                  dict[key] = value;
                }
                break;
            }
            if (Object.keys(infoObj).length) {
              if (key === "phone") {
                dict["phoneObj"] = infoObj;
              } else dict[key] = infoObj;
            }
          }
        });

        const infoDataObj: { [key: string]: DictType } = {};

        if (Object.keys(dict).length) {
          infoDataObj[group.id] = dict;
          return (
            <ErrorBoundary FallbackComponent={ErrorFallback} key={group.id}>
              <StaffOneInfoOne
                data={infoDataObj}
                componentRef={componentRef}
                openedWidgets={openedWidgets || []}
                changeOpenedWidgets={changeOpenedWidgets}
                isScroll={isScroll}
              />
            </ErrorBoundary>
          );
        }
      })}
      <ErrorBoundary FallbackComponent={ErrorFallback}>
        <StaffOneInfoOne
          openedWidgets={openedWidgets || []}
          data={{ "Настройки для приложения": {} }}
          changeOpenedWidgets={changeOpenedWidgets}
          isScroll={isScroll}
        />
      </ErrorBoundary>
    </>
  );
};

export default observer(StaffOneInfo);
