import styles from "./staffOneInfoOneDismissBlock.module.scss";
import { DictType } from "modules/StaffModule/StaffOne/StaffOneInformation/StaffOneInfo/index";
import { useState } from "react";
import { useParams } from "react-router-dom";
import StaffOneInfoOneField from "../StaffOneInfoOneField";
import { useStores } from "stores";
// import { Link } from "react-router-dom";
import { fileUrl } from "stores/utils/consts";
import { getFormattedDate } from "shared/utils/helpers/getFormattedDate";
import { Badge } from "shared/ui/Badge";
import { isEmpty } from "lodash";

type StaffOneInfoDismissBlockProps = {
  data: DictType;
  value: {
    id: string;
    title: string;
  };
  i: number;
};

const StaffOneInfoDismissBlock = ({
  data,
  value,
  i
}: StaffOneInfoDismissBlockProps) => {
  const { staffOneStore } = useStores();
  const [isMoreInfro, setIsMoreInfo] = useState(false);

  const { id } = useParams();

  return (
    <div
      className={`${styles.dismissCompanyItem} ${
        !isMoreInfro ? styles.dismissCompanyItem_hover : ""
      }`}
    >
      <div>{i + 1}.</div>
      {!isMoreInfro ? (
        <div className={styles.dismissCompanyInfoShort}>
          <div className="d-flex justify-content-between">
            <div className={styles.dismissCompanyInfoShort_header}>
              <Badge
                text={value["title"]}
                colorName={
                  !isEmpty(staffOneStore.companiesList) && value.id
                    ? staffOneStore.companiesList[value.id]?.color
                    : "primary"
                }
              />
              {data["dismiss_date"] ? (
                <div>{getFormattedDate(data["dismiss_date"])}</div>
              ) : (
                ""
              )}
              {data["black_list"] || data["grey_list"] ? (
                <div className="d-flex">
                  {data["black_list"] ? (
                    <div className={styles.statusListBtn}>ЧС Компании</div>
                  ) : null}
                  {data["grey_list"] ? (
                    <div className={styles.statusListBtn_gray}>СС Проекта</div>
                  ) : null}
                </div>
              ) : null}
            </div>
            <div
              className={styles.blueBtn}
              id="StaffOneInfoDismissBlock_showMoreInfro"
              onClick={() => setIsMoreInfo(true)}
            >
              Подробно
            </div>
          </div>
          {data["dismiss_position"] ? (
            <div>{data["dismiss_position"]}</div>
          ) : null}
          {data["dismiss_object"] ? (
            <div>
              <a
                className={styles.buildingTitle}
                href={`${fileUrl}/crm/building/?id=${
                  staffOneStore.building_staff_events[id]?.[
                    value["id"]
                  ]?.[0]?.[0]?.["building"]
                }`}
                target="_blank"
                rel="noreferrer"
              >
                {data["dismiss_object"][value["id"]]}
              </a>
              {/* <Link
                className={styles.buildingTitle}
                to={`../../building/id=${
                  staffOneStore.building_staff_events[value["id"]]?.[0]?.[0]?.[
                    "building"
                  ]
                }/timesheet`}
                target="_blank"
              >
                {data["dismiss_object"][value["id"]]}
              </Link> */}
            </div>
          ) : null}
        </div>
      ) : (
        <div className={styles.dismissCompanyInfoFull}>
          <div className={styles.firstRow}>
            <div className={styles.valueRow}>
              <div className={styles.field}>
                <p className={styles.fieldTitle}>Компания</p>
                <p className={styles.fieldValue}>{value["title"]}</p>
              </div>

              {data["dismiss_date"] ? (
                <div className={styles.field}>
                  <p className={styles.fieldTitle}>
                    {staffOneStore.getTitleField("dismiss_date")}
                  </p>
                  <p className={styles.fieldValue}>
                    {getFormattedDate(data["dismiss_date"])}
                  </p>
                </div>
              ) : null}
            </div>
            <div
              className={styles.blueBtn}
              id="StaffOneInfoDismissBlock_showShortInfro"
              onClick={() => setIsMoreInfo(false)}
            >
              Кратко
            </div>
          </div>
          <StaffOneInfoOneField data={data} dismissCompany={value["id"]} />
        </div>
      )}
    </div>
  );
};

export default StaffOneInfoDismissBlock;
